.dashBg {
    background-color: #e8ebef;
}

.dashSelectBg {
    width: 335px;
    height: 61vh;
    margin: 20px 30px 29px 20px;
    padding: 40.9px 0 72px 36px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
}
.dashSelectImage{
    width: 149.1px;
    height: 149.1px;
    margin: 0 114.4px 12px 59.4px;
    object-fit: contain;
}
.dashSelectP1{
    width: 236px;
    height: 17px;
    margin: -10px 71px 50px 14px;
    font-family: WelcomeOTFBold;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.dashSelectDot{
    width: 16px;
    height: 16px;
    margin: -20px 15.3px 25px -10px;
    border-radius: 8px;
    background-color: #004a9b;
}
.dashSelectLogo {
    width: 223.5px;
    height: 42.5px;
    margin: -11px 83.9px 12.5px 35.6px;
    object-fit: contain;
    position: absolute;
    cursor: pointer;
}
.dashSelectP2 {
    width: 236px;
    height: 14px;
    margin: 12.5px 71px 66.7px 26px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
.dashSelectUserImage {
    width: 22.3px;
    height: 25.3px;
    margin: 24px 26px 60.5px 56px;
    object-fit: contain;
}
.dashSelectUserSpan {
    width: 267px;
    height: 29px;
    margin: 56.5px 0 56.5px -40px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    top: -90px;
}
.dashSelectUserButton {
    width: 335px;
    height: 75px;
    margin: -20px 0 0 -36px;
}
.dashSelectUserButton:hover {
    background-color: #dae8fa;
}
.dashSelectListImage {
    width: 22.4px;
    height: 20.4px;
    margin: 26px 28.3px 64.1px 56px;
    object-fit: contain;
}
.dashSelectListSpan {
    width: 267px;
    height: 29px;
    margin: 56.5px 0 56.5px -40px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    top: -92px;
}
.dashSelectListButton{
    width: 335px;
    height: 75px;
    margin: -68px 0 0 -36px;
}
.dashSelectListButton:hover {
    background-color: #dae8fa;
}
.dashSelectCarImage {
    width: 21.3px;
    height: 20.4px;
    margin: 45px 28.6px 6.6px 56px;
    object-fit: contain;
    position: relative;
    top: -15px;
}
.dashSelectCarSpan {
    width: 267px;
    height: 29px;
    margin: 56.5px 0 0 -40px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    top: -51px;
}
.dashSelectCarButton{
    width: 335px;
    height: 75px;
    margin: -70px 0 0 -36px;
}
.dashSelectCarButton:hover {
    background-color: #dae8fa;
}
.dashSelectButtonSet {
    position: relative;
    top: -20px;
}
.userClick {
    background-color: #dae8fa;
    color:  #00309b;
}
.resClick {
    background-color: #dae8fa;
    color:  #00309b;
}
.carClick {
    background-color: #dae8fa;
    color:  #00309b;
}

.dashRefuelBg {
    width: 335px;
    height: 30vh;
    margin: 0 0 0 20px;
    padding: 20px 41px 20.6px 14.5px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    bottom: 30px;
}
.dashRefuelGB {
    width: 15px;
    height: 15px;
    margin: 7px 15px 32.7px 12.5px;
    border-radius: 2px;
    background-color: #26c9a8;
    position: relative;
}
.dashRefuelSpan {
    width: 267px;
    height: 29px;
    margin: -10px 10px 25.7px 25px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
}
.dashRefuelMorning {
    width: 11px;
    height: 11px;
    margin: 22.2px 4.1px 3.2px 0;
    border-radius: 50%;
    background-color: #fea031;
    position: absolute;
    right: 90px;
    z-index: 999;
}
.dashRefuelMorningSpan {
    width: 61px;
    height: 14px;
    margin: -2.5px 0 0 14.1px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #8e8e8f;
    position: absolute;
}
.dashRefuelMorningH1 {
    width: 45px;
    height: 35px;
    margin: 43.8px 12.6px 35.9px 47.2px;
    font-family: S-CoreDream-5;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    position: absolute;
    right: 28px;
}
.dashRefuelTucson {
    width: 11px;
    height: 11px;
    margin: 112.2px 4.2px 3.2px 0;
    border-radius: 50%;
    background-color: #69a6f7;
    position: absolute;
    right: 90px;
    z-index: 999;
}
.dashRefuelTucsonSpan {
    width: 47px;
    height: 14px;
    margin: -2.5px 0 0 14.5px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #8e8e8f;
    position: absolute;
    left: 1px;
}
.dashRefuelTucsonH1 {
    width: 45px;
    height: 35px;
    margin: 132.5px 12.6px 21.3px 47.2px;
    font-family: S-CoreDream-5;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    position: absolute;
    right: 32px;
}
.dashRefuelCarSet {
    position: relative;
    left: 40px;
    bottom: 2px;
}
.dashRefuelCircleChart {
    width: 300px;
    height: 23vh;
    position: relative;
    right: 50px;
    bottom: 15px;
}

.DashCarBgT {
    width: 445px;
    height: 26vh;
    margin: 20px 0 30px 390px;
    padding: 26px 43px 60.8px 28px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
}
.DashCarTBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px -30px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 30px;
}
.DashCarTSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4.5px;
    right: -0.5px;
}
.DashCarTSpan2 {
    width: 163px;
    height: 29px;
    margin: -1px 77px 41.9px 12px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}
.DashCarTImage {
    width: 161.7px;
    height: 150.3px;    
    margin: 20px 7.1px 0 117.1px;
    object-fit: contain;
}
.DashCarTH1 {
    width: 267px;
    height: 69px;
    margin: -122px 47px 48.2px 60px;
    text-shadow: 0 0 3px #fff;
    font-family: S-CoreDream-5;
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
    position: absolute;
}
.DashCarTSpan {
    width: 78px;
    height: 23px;
    margin: -75px 0 62.2px 300px;
    font-family: S-CoreDream-4;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
    position: absolute;
}
.DashCarBgM {
    width: 445px;
    height: 26vh;
    margin: 20px 0 30px 870px;
    padding: 26px 43px 60.8px 28px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
}
.DashCarMBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px -30px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 30px;
}
.DashCarMSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4.5px;
    right: -0.5px;
}
.DashCarMSpan2 {
    width: 163px;
    height: 29px;
    margin: -1px 77px 41.9px 12px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}
.DashCarMImage {
    width: 179.7px;
    height: 150.3px;    
    margin: 20px 7.1px 0 107.1px;
    object-fit: contain;
}
.DashCarMH1 {
    width: 267px;
    height: 69px;
    margin: -122px 47px 48.2px 60px;
    text-shadow: 0 0 3px #fff;
    font-family: S-CoreDream-5;
    font-size: 60px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
    position: absolute;
}
.DashCarMSpan {
    width: 78px;
    height: 23px;
    margin: -75px 0 62.2px 300px;
    font-family: S-CoreDream-4;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
    position: absolute;
}

.DashCalendarBg {
    width: 925px;
    height: 65vh;
    margin: 30px 30px 0 390px;
    padding: 28px 28.4px 37.8px 28px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    bottom: 30px;
}
.DashCalendarBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px -30px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 30px;
}
.DashCalendarSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4px;
    right: -0.5px;
}
.DashCalendarSpan2 {
    width: 190px;
    height: 29px;
    margin: -1px 77px 41.9px 12px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}
.DashCalendarResButton {
    width: 70px;
    height: 22px;
    margin: -3px -4px 2px 8px;
    font-family: Arial;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2563eb;
    transition: all .2s linear;
}
.DashCalendarResButton:hover {
    color: #004a9b;
    transform: scale(1.2);
}
.DashCalendarResSpan {
    width: 50px;
    height: 14px;
    margin: 0px 6.3px 2.3px 3px;
    font-family: S-CoreDream-4;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    bottom: 4.4px;
}
.DashCalendarHr {
    width: 865.2px;
    height: 1.2px;
    margin: 22px 0 16.5px 0.4px;
    background-color: #e8ebef;
}
/* .DashCLMonthSquare {
    width: 10px;
    height: 10px;
    margin: 22.4px 8.3px 14.9px 4.8px;
    background-color: #26c9a8;
}
.DashCLMonthSpan {
    width: 215px;
    height: 21px;
    margin: 16.5px 66.6px 9.7px 18.3px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    top: -9px;
} */
.DashCalendarRed {
    width: 10px;
    height: 10px;
    margin: 6.5px 8.5px 6.5px 461.9px;
    background-color: #ff6767;
    border-radius: 50%;
    position: relative;
    right: 115px;
}
.DashCalendarRedSpan {
    width: 28px;
    height: 14px;
    margin: 0 6.3px 2.3px 4.3px;
    font-family: S-CoreDream-4;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    bottom: -4px;
    left: 10px;
}
.DashCalendarGreen {
    width: 10px;
    height: 10px;
    margin: 6.5px 4.3px 3.3px 6.3px;
    background-color: #26c9a8;
    border-radius: 50%;
    position: relative;
    right: 60px;
}
.DashCalendarGreenSpan {
    width: 28px;
    height: 14px;
    margin: 2.3px 6.3px 2.3px 4.3px;
    font-family: S-CoreDream-4;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    bottom: -3px;
    left: 10px;
}
.DashCalendarBall {
    top: 35px;
    right: 55px;
    position: absolute;
}
.DashCLSunday {
    color: #FF0000;
}
.react-calendar.DashCL {
    width: 95%;
    max-width: 100%;
    background-color: #ffffff00;
    font-family: S-CoreDream-4;
    color: #707070;
    position: absolute;
    top: 15px;
    left: 22px;
}
/* .DashCLWeekSquare {
    width: 10px;
    height: 10px;
    margin: 442.4px 8.3px 14.9px 4.8px;
    background-color: #26c9a8;
}
.DashCLWeekSpan {
    width: 215px;
    height: 21px;
    margin: 16.5px 66.6px 9.7px 18.3px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    top: -9px;
} */
.DashCList {
    width: 810px;
    max-height: 46vh;
    min-height: 46vh;
    overflow-y: auto;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    left: 30px;
}

.DashNumberBg {
    width: 550px;
    height: 28.5vh;
    margin: 130px 0 36px 1350px;
    padding: 24px 36.3px 18.5px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
}
.DashNumberBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px -30px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 30px;
}
.DashNumberSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4.5px;
    right: -0.5px;
}
.DashNumberSpan2 {
    width: 190px;
    height: 29px;
    margin: -1px 77px 41.9px 12px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}
.DashNumberCircle {
    width: 60px;
    height: 60px;
    margin: 21px 52.3px 9.6px 0px;
    padding: 24px 12px 15px 10px;
    background-color: #e8ebefa9;
    border-radius: 50px;
}
.DashNumberNum {
    width: 58px;
    height: 41px;
    font-family: S-CoreDream-4;
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
    position: relative;
    bottom: 19px;
    right: 9px;
    display: inline-block;
}
.DashNumberNumS {
    width: 58px;
    height: 41px;
    font-family: S-CoreDream-4;
    font-size: 35px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
    position: relative;
    bottom: 1px;
    left: 12px;
}
.DashNumberTeam {
    width: 151px;
    height: 18px;
    margin: 8.5px 12px 0 -42px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.DashNumberSet {
    float: left;
    width: 150px;
    position: relative;
    left: 55px;
    bottom: 8px;
}

.DashPlanBg {
    width: 550px;
    height: 51vh;
    margin: 36px 0 0 1350px;
    padding: 28px 0 44px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    bottom: 30px;
}
.DashPlanBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px 0px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 30px;
}
.DashPlanSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4.5px;
    right: -0.5px;
}
.DashPlanSpan2 {
    width: 190px;
    height: 29px;
    margin: -1px 77px 41.9px 12px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}
.DashPlanGB {
    width: 10px;
    height: 10px;
    margin: 36.5px 1.3px 27.5px 0;
    background-color: #26c9a8;
    position: absolute;
    left: 40px;
}
.DashPlanSpan3 {
    width: 397px;
    height: 21px;
    margin: -8px 15.7px 22px 20.7px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
}
.DashPlanMorning {
    width: 11px;
    height: 11px;
    margin: -16.2px 4.1px 38.2px 0.6px;
    border-radius: 50%;
    background-color: #fea031;
    position: relative;
    right: 180px;
}
.DashPlanMorningSpan {
    width: 61px;
    height: 14px;
    margin: -3px 6.2px 35px 20.1px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #8e8e8f;
    position: absolute;
}
.DashPlanTucson {
    width: 11px;
    height: 11px;
    margin: -16.2px 4.2px 38.2px 10.7px;
    border-radius: 50%;
    background-color: #69a6f7;
    position: relative;
    right: 100px;
}
.DashPlanTucsonSpan {
    width: 47px;
    height: 14px;
    margin: -3px 47px 35px 22.2px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #8e8e8f;
    position: absolute;
}
.DashPlanMonthChart {
    width: 500px;
    height: 30vh;
    position: relative;
    top: 4.5vh;
    left: 15px;
}
.DashPlanMorningH1 {
    width: 150px;
    height: 35px;
    margin: 41.2px 0 0 108.1px;
    font-family: S-CoreDream-5;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    position: relative;
}
.DashPlanMorningSpan2 {
    width: 60px;
    height: 23px;
    margin: 12px 0.5px 3.2px 4.3px;
    font-family: S-CoreDream-4;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8e8e8f;
}
.DashPlanLine {
    height: 60px;
    margin: 26.4px 0 21.2px 30px;
    border: dashed #e8ebef 1px;
    position: relative;
}
.DashPlanTucsonH1 {
    width: 150px;
    height: 35px;
    margin: 41.2px 9.3px 0 36.8px;
    font-family: S-CoreDream-5;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    position: relative;
}
.DashPlanTucsonSpan2 {
    width: 60px;
    height: 23px;
    margin: 12px 41px 3.2px 4.3px;
    font-family: S-CoreDream-4;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8e8e8f;
}

@media (min-width: 2560px) {
    .DashCarBgT {
        width: 445px;
        height: 26vh;
        margin: 20px 0 30px 790px;
        padding: 26px 43px 60.8px 28px;
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
        background-color: #fff;
        position: absolute;
    }
    .DashCarBgM {
        width: 445px;
        height: 26vh;
        margin: 20px 0 30px 1270px;
        padding: 26px 43px 60.8px 28px;
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
        background-color: #fff;
        position: absolute;
    }
    .DashCalendarBg {
        width: 925px;
        height: 65vh;
        margin: 30px 30px 0 790px;
        padding: 28px 28.4px 37.8px 28px;
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
        background-color: #fff;
        position: absolute;
        bottom: 30px;
    }
    .DashNumberBg {
        width: 550px;
        height: 28.5vh;
        margin: 130px 0 36px 1850px;
        padding: 24px 36.3px 18.5px;
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
        background-color: #fff;
        position: absolute;
    }
    .DashPlanBg {
        width: 550px;
        height: 51vh;
        margin: 36px 0 0 1850px;
        padding: 28px 0 44px;
        border-radius: 20px;
        box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
        background-color: #fff;
        position: absolute;
        bottom: 30px;
    }
}