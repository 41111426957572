.ResListBg {
    width: 81%;
    height: 34vh;
    margin: 20px 0 0 24.4px;
    padding: 31px 52px 42px 47px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    bottom: 30px;
    right: 25px;
    z-index: 9;
}
.ResListBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px 0;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    top: 3px;
}
.ResListP {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4px;
    right: -0.5px;
}
.ResListSpan {
    width: 142px;
    height: 29px;
    margin: 0 594px 14px 8px;
    font-family: WelcomeOTFBold;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}
.ResListResButton {
    width: 75px;
    height: 27px;
    margin: 10px 0 5px 594px;
    padding: 2.9px 0 3.1px 15px;
    border-radius: 2.5px;
    background-color: #00309b;
    margin: 0.5px 0 0 5.5px;
    font-family: S-CoreDream-4;
    font-size: smaller;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    position: relative;
    top: 18px;
    right: -26px;
}
.ResListResButton:hover {
  opacity: 0.9;
}
.ResListResIcon {
    width: 13.8px;
    height: 14px;
    margin: 0 24.6px 0.5px 0;
    object-fit: contain;
    position: relative;
    bottom: 15.5px;
    right: 5px;
}

.ResList {
    overflow: auto;
    height: 22.5vh;
    width: 102%;
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    top: 28.5px;
    background-color: white;
}

.ResListTable {
    width: 100%;
    font-family: S-CoreDream-4;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.ResListThead {
    width: 76.4%;
    height: 30px;
    margin: 5px 3px 2.5px 0;
    padding: 4px 24.5px 3.5px 26.1px;
    background-color: #dae8fa;
    position: fixed;
    bottom: 24.5vh;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    z-index: 9;
    
}
.ResListTr {
    position: relative;
    bottom: 12.5px;
}
.ResListSelect {
    background-color: #dae8fa;
    font-family: S-CoreDream-4;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.ResListTbody {
  width: 500px;
  height: 30px;
  margin: 5px 3px 2.5px 0;
  background-color: #fcfcfc;
  border-bottom: white solid 4px;
  position: relative;
  top: 27px;
  /* top: 43.5px; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ResListTbodyTr {
  max-width: 80%;
}

.ResReturnButton {
  width: 75px;
  height: 27px;
  padding: 4.8px 0 4.8px 10.4px;
  border-radius: 5px;
  background-color: #69a6f7;
	position: relative;
	top: 11px;
	left: 125px
}
.ResReturnButton2 {
  width: 75px;
  height: 27px;
  padding: 4.8px 0 4.8px 10.4px;
  border-radius: 5px;
  background-color: #69a6f7;
	position: relative;
	top: 11px;
	left: 150px
}
.ResReturnSpan {
  width: 52px;
  height: 14px;
  margin: 2.1px 0 1.4px 12.6px;
  font-family: S-CoreDream-4;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
	position: relative;
	top: -21px;
}
.ResReturnIcon{
	width: 17px;
  height: 17.5px;
  object-fit: contain;
}
.ResCancelButton {
  width: 75px;
  height: 27px;
  margin: 0 0 0 5px;
  padding: 6.9px 0 6.1px 14px;
  border-radius: 5px;
  background-color: #ff6767;
	position: relative;
	top: 10px;
  left: 15px;
}
.ResCancelButton2 {
  width: 75px;
  height: 27px;
  margin: 0 0 0 5px;
  padding: 6.9px 0 6.1px 14px;
  border-radius: 5px;
  background-color: #ff6767;
	position: relative;
	top: 10px;
  left: 25px;
}
.ResCancelSpan {
	width: 52px;
  height: 14px;
  margin: 0 0 0 8px;
  font-family: S-CoreDream-4;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
	position: relative;
	top: -19px;
  color: #fff;
}
.ResCancelIcon {
	width: 10.6px;
  height: 13.3px;
  margin: 0 50.4px 0.7px 0;
  object-fit: contain;
}

.ResListDetailsButton {
  width: 110px;
  height: 27px;
  position: relative;
  left: 650px;
  top: 19px;
  z-index: 999;
  background-color: #00309b;
  border-radius: 2.5px;
  padding: 3px 8px 0 0;
  font-family: S-CoreDream-4;
  font-size: smaller;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}
.ResListDetailsImage {
  width: 18px;
  height: 18px;
  object-fit: contain;
  position: relative;
  left: 5px;
  bottom: 18px;
}
.ResListDetailsButton:hover {
  opacity: 0.9;
}
@media (min-height: 1080px) {
  .ResListThead {
      width: 76.4%;
      height: 30px;
      margin: 5px 3px 2.5px 0;
      padding: 4px 24.5px 3.5px 26.1px;
			background-color: #dae8fa;
      position: fixed;
      bottom: 26vh;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      z-index: 9;
  }
}
