.userBaseBg {
    background-color: #e8ebef;
}

.userManageBg {
    width: 1507px;
    height: 94.8vh;
    margin: 0 0 0 30px;
    padding: 26px 51px 40px 54px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    right: 20px;
    top: 20px;
}
.userManageBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px -30px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 35px;
    top: 70px;
}
.userManageSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4.5px;
    right: -0.5px;
}
.userManageSpan2 {
    width: 163px;
    height: 29px;
    margin: -1px 77px 41.9px 12px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}
.userManageAddButton {
    position: absolute;
    right: 56px;
    top: 115px;
    z-index: 999;
}
.userManageAddImage {
    width: 27px;
    height: 27px;
    object-fit: contain;
}

.userManagementSearchSelect {
    width: 132px;
    height: 36px;
    margin: 25px 10px 0 171px;
    padding-left: 35px;
    border: solid 1px #d0d0d0;
    background-color: #fff;
    outline: none;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #707070;
}
.userManagementSearchSelectOption {
    width: 70px;
    height: 18px;
    margin: 0 14.4px 0 0;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
.userManagementSearchInput {
    width: 300px;
    height: 36px;
    margin: 25px 10px 0 0;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px #d0d0d0;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #707070;
    outline: none;
}
.userManagementSearchButton {
    width: 100px;
    height: 36px;
    margin: 25px 41px 0 0px;
    padding: 8.5px 6.5px 6.5px 13.2px;
    border-radius: 5px;
    background-color: #69a6f7;
    position: relative;
    top: 25px;
}
.userManagementSearchImg {
    width: 18px;
    height: 17.5px;
    margin: 1.7px 8.2px 1.7px 0;
    object-fit: contain;
}
.userManagementSearchSpan {
    width: 54px;
    height: 21px;
    margin: 0 0 0 8.2px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    position: relative;
    bottom: 23px;
}
.userManagementSearchSet {
    position: absolute;
    bottom: 20px;
    left: 260px;
    z-index: 999;
}

.userManageTable {
    width: 1392px;
}
.userManageThead {
    width: 1402px;
    height: 50px;
    margin: -50px 80px 0 0;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    background-color: #dae8fa;
    position: fixed;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.userModifyButton {
    width: 75px;
    height: 27px;
    padding: 4px 0 4.8px 10.4px;
    border-radius: 5px;
    background-color: #69a6f7;
    position: relative;
    top: 11px;
    left: 60%;
}
.userModifySpan {
    width: 52px;
    height: 14px;
    margin: 2.1px 0 1.4px 12.6px;
    font-family: S-CoreDream-4;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    position: relative;
    top: -16px;
}
.userModifyIcon{
    width: 17px;
    height: 17.5px;
    object-fit: contain;
}
.userDeleteButton {
    width: 75px;
    height: 27px;
    margin: 0 0 0 5px;
    padding: 4px 0 4.8px 10.4px;
    border-radius: 5px;
    background-color: #ff6767;
    position: relative;
    top: 11px;
}
.userDeleteSpan {
    width: 52px;
    height: 14px;
    margin: 2.1px 0 1.4px 12.6px;
    font-family: S-CoreDream-4;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    position: relative;
    top: -18px;
    color: #fff;
}
.userDeleteIcon {
    width: 17px;
    height: 16.5px;
    margin: 1.5px 50.4px 0.7px 0;
    object-fit: contain;
}
.userManageList {
    overflow-y: auto;
    overflow-x: hidden;
    height: 65vh;
    position: relative;
    top: 16vh;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.userManageTbody {
    width: 1342px;
    height: 50px;
    margin: 9px 0 8px;
    padding: 12px 112px 11px 38px;
    background-color: #fcfcfc;
    border-bottom: white solid 4px;
    font-family: S-CoreDream-5;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.userManageTbodyTh {
    width: 145px;
    height: 15px;
    margin: 6px 5px 6px 0;
    font-family: S-CoreDream-5;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}

.addUser {
    font-size: medium;
    font-weight: bold;
    color: #2563eb;
}
.addButton {
    height: 5vh;
    cursor: pointer;
}
.delete {
    font-size: smaller;
    color: #2563eb;
    font-weight: bold;
}

@media (min-width: 1240px) {
    .userBase {
        height: 57vh;
    }
    .userTable {
        margin-top: 3.5%;
        margin-left: 5%;
        width: fit-content;
    }
    .addUser {
        position: relative;
        top: 5vh;
        right: 13%;
    }
    .addButton {
        position: relative;
        top: 5vh;
        right: 14%;
    }
}
@media (min-height: 633px) {
    .userBase {
        height: 58vh;
    }
    .userTable {
        margin-top: 6%;
        margin-left: 5%;
        width: fit-content;
    }
    .addUser {
        position: relative;
        top: 8vh;
        right: 13%;
    }
    .addButton {
        position: relative;
        top: 8vh;
        right: 14%;
    }
}
@media (min-width: 1880px) {
    .userBase {
        height: 65vh;
    }
    .userTable {
        margin-top: 5%;
        margin-left: 20%;
        width: fit-content;
    }
    .addUser {
        position: relative;
        top: 8vh;
        right: 27%;
    }
    .addButton {
        position: relative;
        top: 8vh;
        right: 28%;
    }
}
@media (min-height: 993px) {
    .userBase {
        height: 70vh;
    }
    .userTable {
        margin-top: 6%;
        margin-left: 20%;
        width: fit-content;
    }
    .addUser {
        position: relative;
        top: 8vh;
        right: 27%;
    }
    .addButton {
        position: relative;
        top: 8vh;
        right: 28%;
    }
}