.ResMorningBall {
    width: 8px;
    height: 8px;
    margin: 7px 4.3px 3.3px 6.3px;
    background-color: #26c9a8;
    border-radius: 50%;
    position: relative;
    right: 5px;
}
.ResMorningData {
    width: 50px;
    height: 7px;
    margin: 2.3px 6.3px 2.3px -10px;
    font-family: S-CoreDream-4;
    font-size: x-small;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    bottom: -0.5px;
    left: 5px;
}
.ResMorningSet {
    position: absolute;
    right: 30px;
    top: 0.5px;
}

.ResTucsonBall {
    width: 8px;
    height: 8px;
    margin: 7px 4.3px 3.3px 6.3px;
    background-color: #ff6767;
    border-radius: 50%;
    position: relative;
    left: -7px;
    top: 8.5px;
}
.ResTucsonData {
    width: 50px;
    height: 7px;
    margin: 2.3px 6.3px 2.3px 6px;
    font-family: S-CoreDream-4;
    font-size: x-small;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    bottom: 12px;
    left: 5px;
}
.ResTucsonSet {
    position: absolute;
    left: 30px;
    top: -9px;
}

.AdminResMorningBall {
    width: 8px;
    height: 8px;
    margin: 7px 4.3px 3.3px 6.3px;
    background-color: #26c9a8;
    border-radius: 50%;
    position: relative;
    right: 5px;
}
.AdminResMorningData {
    width: 50px;
    height: 7px;
    margin: 2.3px 6.3px 2.3px -10px;
    font-family: S-CoreDream-4;
    font-size: x-small;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    bottom: -2px;
    left: 5px;
}
.AdminResMorningSet {
    position: absolute;
    right: -15px;
    top: -3px;
}

.AdminResTucsonBall {
    width: 8px;
    height: 8px;
    margin: 7px 4.3px 3.3px 6.3px;
    background-color: #ff6767;
    border-radius: 50%;
    position: relative;
    left: -7px;
    top: 8.5px;
}
.AdminResTucsonData {
    width: 50px;
    height: 7px;
    margin: 2.3px 6.3px 2.3px 6px;
    font-family: S-CoreDream-4;
    font-size: x-small;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    bottom: 12px;
    left: 5px;
}
.AdminResTucsonSet {
    position: absolute;
    left: 15px;
    top: -11.5px;
}