.ValidateId {
    font-size: small;
    position: absolute;
    color: #ef4444;
    font-weight: bold;
    top: 418px;
    left: 35.4%;
}
.ValidatePassword {
    font-size: small;
    position: absolute;
    color: #ef4444;
    font-weight: bold;
    top: 480px;
    left: 35.4%;
}
.ValidateReservation {
    font-size: small;
    position: absolute;
    color: #ef4444;
    font-weight: bold;
    top: 380px;
    left: 35.4%;
    z-index: 9999;
}
/* @media (min-width: 1240px) {
    .ValidateId {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 600vh;
        left: 56.4%;
        z-index: 99;
    }
    .id {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 0vh;
        left: 56.4%;
        z-index: 99;
    }
    .userId {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 10vh;
        left: 30%;
        z-index: 99;
    }
    .password {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 54vh;
        left: 53.5%;
        z-index: 99;
    }
}
@media (min-height: 633px) {
    .id {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 46.5vh;
        left: 56.4%;
        z-index: 99;
    }
    .password {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 53.5vh;
        left: 53.5%;
        z-index: 99;
    }

}
@media (min-width: 1880px) {
    .id {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 43.2vh;
        left: 54%;
        z-index: 99;
    }
    .password {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 48.3vh;
        left: 52%;
        z-index: 99;
    }
}
@media (min-height: 993px) {
    .id {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 44.5vh;
        left: 54%;
        z-index: 99;
    }
    .password {
        font-size: small;
        position: absolute;
        color: #ef4444;
        font-weight: bold;
        top: 49vh;
        left: 52%;
        z-index: 99;
    }
} */