.ReturnModalBg {
  width: 550px;
  height: 830px;
  margin: 55px 653px 130px 720px;
  padding: 34px 16.6px 0 4.9px;
  border-radius: 10px;
  background-color: #fff;
}
.ReturnXButton {
  width: 22px;
  height: 22px;
  margin: -10px 0px 2.8px 58px;
  padding: -12px 5px 8px 4px;
  background-color: #ff6767;
  border-radius: 5px;
}
.ReturnXButtonSpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ReturnModalBB {
  width: 15px;
  height: 15px;
  margin: 3px 8px 4px 0px;
  padding-left: 2.5px;
  border-radius: 2px;
  background-color: #0a6ab3;
  color: #fff;
  font-size: xx-small;
  position: relative;
  left: 30px;
}
.ReturnModalSpan1 {
  color: #fff;
  font-size: small;
  position: relative;
  top: -2.5px;
  right: 0px;
}
.ReturnModalSpan2 {
  width: 170px;
  height: 29px;
  margin: 0px 0px 0px 8px;
  font-family: WelcomeOTFBold;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
  top: -10px;
  left: 20px;
}

.ReturnDateImage {
  width: 20.2px;
  height: 21.6px;
  margin: 33px 10px 2px 45px;
  object-fit: contain;
}
.ReturnDateLabel {
  width: 76px;
  height: 18px;
  margin: 32px 35px 14px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ReturnDateInput {
  width: 289px;
  height: 35px;
  margin: 25px 43.4px 5px 0px;
  padding: 8px 47px 9px 21px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #d0d0d0;
}

.ReturnUserImage {
  width: 20.2px;
  height: 21.6px;
  margin: 13.4px 10px 2px 45px;
  object-fit: contain;
}
.ReturnUserLabel {
  width: 56px;
  height: 18px;
  margin: 12px 56px 1px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ReturnUserInput {
  width: 289px;
  height: 35px;
  margin: 5px 43.4px 5px -1px;
  padding: 10px 212px 7px 21px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.ReturnDestinationBB {
  width: 5px;
  height: 5px;
  margin: 18px 0 18.8px 74px;
  background-color: #69a6f7;
}
.ReturnDestinationLabel {
  width: 56px;
  height: 18px;
  margin: -8px 41px 10px 12.6px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
}
.ReturnDestinationInput {
  width: 289px;
  height: 35px;
  margin: -14px 43.4px 5px 112px;
  padding: 9px 96px 8px 21px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ReturnMileageBB {
  width: 5px;
  height: 5px;
  margin: 38px 0 18.8px 74px;
  background-color: #69a6f7;
}
.ReturnMileageLabel {
  width: 90px;
  height: 18px;
  margin: -7px 18px 13px 12.6px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
}
.ReturnMileageInput {
  width: 289px;
  height: 35px;
  margin: -14px 43.4px 5px 112px;
  padding: 10px 96px 7px 21px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ReturnCheckImage {
  width: 20.2px;
  height: 21.6px;
  margin: 15px 10px 14.4px 45px;
  object-fit: contain;
}
.ReturnCheckLabel {
  width: 107px;
  height: 18px;
  margin: 16px 5px 15.4px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.ReturnDamageBB {
  width: 5px;
  height: 5px;
  margin: 10px 0 18.8px 74px;
  background-color: #69a6f7;
}
.ReturnDamageLabel {
  width: 339px;
  height: 18px;
  margin: -8px 90.4px 5px 12.6px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
}
.ReturnDamageInput {
  width: 386px;
  height: 35px;
  margin: -7px 43.4px 15px 89px;
  padding: 9px 14px 8px 14px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ReturnLightBB {
  width: 5px;
  height: 5px;
  margin: 10px 0 18.8px 74px;
  background-color: #69a6f7;
}
.ReturnLightLabel {
  width: 339px;
  height: 18px;
  margin: -8px 90.4px 5px 12.6px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
}
.ReturnLightInput {
  width: 386px;
  height: 35px;
  margin: -7px 43.4px 15px 89px;
  padding: 8px 14px 8px 14px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ReturnDashBB {
  width: 5px;
  height: 5px;
  margin: 10px 0 18.8px 74px;
  background-color: #69a6f7;
}
.ReturnDashLabel {
  width: 339px;
  height: 18px;
  margin: -8px 90.4px 5px 12.6px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
}
.ReturnDashInput {
  width: 386px;
  height: 35px;
  margin: -7px 43.4px 15px 89px;
  padding: 9px 14px 8px 14px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ReturnBesideBB {
  width: 5px;
  height: 5px;
  margin: 10px 0 18.8px 74px;
  background-color: #69a6f7;
}
.ReturnBesideLabel {
  width: 339px;
  height: 18px;
  margin: -8px 90.4px 5px 12.6px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
}
.ReturnBesideInput {
  width: 386px;
  height: 35px;
  margin: -7px 43.4px 8px 89px;
  padding: 9px 14px 8px 14px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ReturnRefuelImage {
  width: 21.2px;
  height: 22.6px;
  margin: 15px 9px 14.2px 45px;
  object-fit: contain;
}
.ReturnRefuelLabel {
  width: 97px;
  height: 18px;
  margin: 15px 14px 14.2px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ReturnRefuelInput {
  width: 289px;
  height: 35px;
  margin: 8px 43.4px 7px 0px;
  padding: 10px 21px 7px 21px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ReturnParkingImage {
  width: 23.5px;
  height: 19.7px;
  margin: 10px 10.2px 5.2px 45px;
  object-fit: contain;
}
.ReturnParkingLabel {
  width: 97px;
  height: 18px;
  margin: 8px 418px 5.2px 75px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
}
.ReturnParkingInput {
  width: 289px;
  height: 35px;
  margin: 0px 43.4px 8px 108px;
  padding: 10px 21px 3px 21px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ReturnCancelButton {
  width: 105px;
  height: 41px;
  margin: 10px 0px 2.8px 58px;
  padding: 8px 5px 8px 4px;
  background-color: #ff6767;
  border-radius: 10px;
}
.ReturnCancelSpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ReturnButton {
  width: 105px;
  height: 41px;
  margin: 10px 52px 2.8px 17px;
  padding: 8px 5px 8px 4px;
  background-color: #69a6f7;
  border-radius: 10px;
}
.ReturnSpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.ReturnModalCarImage {
  width: 96.5px;
  height: 86.2px;
  margin: -10px 26px 0 0;
  object-fit: contain;
  opacity: 0.5;
}