.mainBg {
    background-color: #e8ebef;
}
.topNav {
    width: 15.5%;
    height: 58vh;
    margin: 25px 12.5px 10px 25px;
    padding: 16px 8px 27px 11px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
}
.userLogo{
    width: 223.4px;
    height: 42.4px;
    margin: 12px 1.2px 6.6px 26px;
    object-fit: contain;
    cursor: pointer;
}
.userAssembble {
    width: 230px;
    height: 14px;
    margin: 6.6px 24px 0;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.userIcon {
    width: 150px;
    height: 120px;
    margin: 0px 71px 23.3px;
    object-fit: contain;
}
.userUser {
    width: 236px;
    height: 17px;
    margin: 46.6px 16px 23px;
    font-family: WelcomeOTFBold;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.userPrivate {
    width: 262px;
    height: 21px;
    margin: 23px 5px;
    font-family: S-CoreDream-4;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.userLogoutButton {
    width: 133px;
    height: 41px;
    margin: 10px 67px 161.6px 70px;
    padding: 8px 16px 10px 19px;
    background-color: #69a6f7;
    border-radius: 5px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
.userInfo {
    width: 22.3px;
    height: 25.3px;
    margin: 4px 18.6px 2px 0;
    object-fit: contain;
}
.userInfoButton {
    width: 179px;
    height: 29px;
    margin: -40px 0 0 44px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
.userRes {
    width: 22.4px;
    height: 20.4px;
    margin: 4px 18.6px 2px 0;
    object-fit: contain;
}
.userResButton {
    width: 179px;
    height: 29px;
    margin: 47.8px 24.4px 66.4px 44px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
.userButtons {
    position: relative;
    bottom: 45px;
    left: 15px;
}

.adminTopNav {
    width: 550px;
    height: 78px;
    margin: 20px 20px 30px 30px;
    padding: 19px 19px 18px 32px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    float: right;
    position: relative;
    border-radius: 10px;
    z-index: 999;
}
.topNavUserClick {
    box-shadow: none;
    position: relative;
    right: 100px;
}
.topNavResClick {
    box-shadow: none;
    position: relative;
    right: 100px;
}
.topNavCarClick {
    box-shadow: none;
    position: relative;
    right: 100px;
}
.adminIcon {
    width: 41px;
    height: 41px;
    margin: 0 17px 0 0;
    padding: 5.6px 4px 0 4.2px;
}
.topNavUserIcon {
    margin: 0 17px 0 100px;
    z-index: 999;
    position: relative;
    right: 40px;
}
.topNavResIcon {
    margin: 0 17px 0 100px;
    z-index: 999;
    position: relative;
    right: 40px;
}
.topNavCarIcon {
    margin: 0 17px 0 100px;
    z-index: 999;
    position: relative;
    right: 40px;
}
.adminHello {
    width: 262px;
    height: 21px;
    margin: 8px 46px 8px 0px;
    font-family: S-CoreDream-4;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
.topNavUserHello {
    width: 500px;
    position: relative;
    right: 40px;
}
.topNavResHello {
    width: 500px;
    position: relative;
    right: 40px;
}
.topNavCarHello {
    width: 500px;
    position: relative;
    right: 40px;
}
.adminLogoutButton {
    width: 133px;
    height: 41px;
    margin: 0 0 0 46px;
    padding: 8px 12px 8px 15px;
    background-color: #69a6f7;
    border-radius: 10px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}
.adminLogoutButton:hover {
    background-color: #6faeff;
}
.topNavUserLogout {
    margin: 0 0 0 0px;
    width: 100px;
    z-index: 999;
    position: absolute;
    left: 490px;
}
.topNavResLogout {
    margin: 0 0 0 0px;
    width: 100px;
    z-index: 999;
    position: absolute;
    left: 490px;
}
.topNavCarLogout {
    margin: 0 0 0 0px;
    width: 100px;
    z-index: 999;
    position: absolute;
    left: 490px;
}
.adminHome {
    width: 133px;
    height: 41px;
    margin: 0 0 0 0px;
    padding: 8px 12px 8px 15px;
    background-color: #69a6f7;
    border-radius: 10px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    display: none;
}
.adminHome:hover {
    background-color: #6faeff;
}
.topNavUserHome {
    display: inline-block;
    width: 80px;
    z-index: 999;
    position: absolute;
    left: 390px;
}
.topNavResHome {
    display: inline-block;
    width: 80px;
    z-index: 999;
    position: absolute;
    left: 390px;
}
.topNavCarHome {
    display: inline-block;
    width: 80px;
    z-index: 999;
    position: absolute;
    left: 390px;
}

@media (min-width: 1240px) {
    .userIcon {
        height: 6vh;
        position: relative;
        right: 10px;
        top: 32px;
    }
}
@media (min-height: 633px) {
    .userButtons {
        position: relative;
        bottom: 50px;
        left: 15px;
    }
}
@media (min-width: 1880px) {
    .userButtons {
        position: relative;
        bottom: 60px;
        left: 15px;
    }
}
@media (min-height: 993px) {
    .userButtons {
        position: relative;
        bottom: 62px;
        left: 15px;
    }
}
