.ResDetailsModalBg {
  width: 1200px;
  height: 770px;
  margin: 85px 0 126px 332px;
  padding: 25px 35px 29px 30px;
  border-radius: 10px;
  border: solid 1px #707070;
  background-color: #fff;
  position: relative;
}
.ResDetailsXButton {
  width: 22px;
  height: 22px;
  margin: -10px 0px 2.8px 52px;
  padding: -12px 5px 8px 4px;
  position: relative;
  top: -38px;
}
.ResDetailsXButtonSpan {
  width: 35px;
  height: 22px;
  margin: 5px 0 2px;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #d0d0d0;
}
.ResDetailsBB {
  width: 15px;
  height: 15px;
  margin: -14px 8px 4px -30px;
  padding-left: 2.5px;
  border-radius: 2px;
  background-color: #0a6ab3;
  color: #fff;
  font-size: xx-small;
  position: relative;
  left: 30px;
}
.ResDetailsSpan1 {
  color: #fff;
  font-size: small;
  position: relative;
  top: -4.5px;
  right: 0px;
}
.ResDetailsSpan2 {
  width: 244px;
  height: 29px;
  margin: 0px 0px 0px 8px;
  font-family: S-CoreDream-5;  
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
  top: -10px;
  left: 20px;
}
.ResDetailsReturnButton {
  width: 95px;
  height: 27px;
  padding: 7.8px 0 4.8px 10.4px;
  border-radius: 5px;
  background-color: #69a6f7;
	position: relative;
	top: -25px;
	left: 280px;
}
.ResDetailsReturnSpan {
  width: 52px;
  height: 14px;
  margin: 2.1px 0 1.4px 12.6px;
  font-family: S-CoreDream-4;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
	position: relative;
	top: -21px;
}
.ResDetailsReturnIcon{
	width: 17px;
  height: 17.5px;
  object-fit: contain;
  position: relative;
  bottom: 3px;
}
.ResDetailsCancelButton {
  width: 95px;
  height: 27px;
  margin: 0 0 0 5px;
  padding: 6.9px 0 6.1px 14px;
  border-radius: 5px;
  background-color: #ff6767;
	position: relative;
  left: 280px;
	top: -25px;
}
.ResDetailsCancelSpan {
	width: 52px;
  height: 14px;
  margin: 0 0 0 8px;
  font-family: S-CoreDream-4;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
	position: relative;
	top: -17px;
  color: #fff;
}
.ResDetailsCancelIcon {
	width: 10.6px;
  height: 13.3px;
  margin: 0 50.4px 0.7px 0;
  object-fit: contain;
}


.ResDetailsBg {
  width: 385px;
  height: 660px;
  margin: 27px 13px 0 0;
  padding: 42.3px 0 30px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
  background-color: #fff;
}
.ResDetailsCarImg {
  width: 161.7px;
  height: 150.3px;
  margin: 0 115.1px 36.4px 108.1px;
  object-fit: contain;
}
.ResDetailsDistance1 {
  width: 267px;
  height: 69px;
  margin: 33.1px 77px 84.6px 61px;
  padding: 40.6px 18px 13.4px 24px;
  text-shadow: 0 0 3px #fff;
  font-family: S-CoreDream-5;
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  color: #8e8e8f;
  position: relative;
  top: -240px;
}
.ResDetailsDistance2 {
  width: 267px;
  height: 69px;
  margin: 33.1px 77px 84.6px 31px;
  padding: 40.6px 18px 13.4px 94px;
  text-shadow: 0 0 3px #fff;
  font-family: S-CoreDream-5;
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  color: #8e8e8f;
  position: relative;
  top: -240px;
}
.ResDetailsKm {
  width: 78px;
  height: 23px;
  margin: 0 152px 2px 25px;
  font-family: S-CoreDream-4;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e8f;
}
.ResDetailsUserBg1 {
  width: 145px;
  height: 45px;
  padding: 13px 36px 11px 41px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResDetailsUserSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsUserBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 13px 44px 11px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  position: relative;
}
.ResDetailsUserSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsPartnameBg1 {
  width: 145px;
  height: 45px;
  padding: 14px 36px 10px 41px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResDetailsPartnameSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsPartnameBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 14px 44px 10px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsPartnameSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsPositionBg1 {
  width: 145px;
  height: 45px;
  padding: 14px 36px 10px 41px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResDetailsPositionSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsPositionBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 14px 44px 10px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsPositionSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsPhoneBg1 {
  width: 145px;
  height: 45px;
  padding: 14px 24px 10px 29px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResDetailsPhoneSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsPhoneBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 14px 44px 10px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsPhoneSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsStartBg1 {
  width: 145px;
  height: 45px;
  margin: 0 5px 0 0;
  padding: 12px 36px 12px 41px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResDetailsStartSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsStartBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 12px 44px 12px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsStartSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsEndBg1 {
  width: 145px;
  height: 45px;
  margin: 0 5px 0 0;
  padding: 13px 36px 11px 41px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResDetailsEndSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsEndBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 13px 44px 11px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsEndSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsDestinationBg1 {
  width: 145px;
  height: 45px;
  margin: 0 5px 0 0;
  padding: 12px 36px 12px 41px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResDetailsDestinationSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsDestinationBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 12px 44px 12px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsDestinationSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsParkingBg1 {
  width: 145px;
  height: 45px;
  margin: 0 5px 0 0;
  padding: 12px 18px 12px 23px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResDetailsParkingSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsParkingBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 12px 44px 12px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsParkingSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.ResDetailsInfo {
  position: relative;
  top: -190px;
}

.ResDetailsResNumBg {
  width: 355px;
  height: 275px;
  margin: 27px 20px 20px;
  padding: 25px 105.5px 106.4px 33px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
  background-color: #fff;
  position: relative;
  bottom: 685px;
  left: 390px;
}
.ResDetailsResNumImg {
  width: 21.6px;
  height: 19.6px;
  margin: 0 10.4px 55px 0;
  object-fit: contain;
}
.ResDetailsResNumSpan1 {
  width: 172px;
  height: 21px;
  margin: -4px 12.5px 52.6px 2.4px;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3a3d3f;
}
.ResDetailsResNumHr {
  width: 295px;
  height: 1px;
  margin: 0 14.5px 0 0px;
  background-color: #d0d0d0;
  position: relative;
  bottom: 40px;
}
.ResDetailsResNumSpan2 {
  width: 84px;
  height: 69px;
  margin: 0 47px 0 0;
  text-shadow: 0 0 3px #fff;
  font-family: S-CoreDream-5;
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e8f;
}
.ResDetailsResNumSpan3 {
  width: 78px;
  height: 18px;
  margin: 38px 0 13px 10px;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e8f;
}
.ResDetailsResNumContents {
  text-align: center;
  position: relative;
  left: 65px;
}
.ResDetailsResNumSpan4 {
  width: 80px;
  height: 18px;
  margin: 3px 29px 0 0px;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: relative;
  top: 40px;
}
.ResDetailsResNumSpan5 {
  width: 100px;
  height: 18px;
  margin: 3px 0px 0 -20px;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: relative;
  top: 40px;
}


.ResDetailsDistanceNumBg {
  width: 355px;
  height: 275px;
  margin: 27px 20px 20px;
  padding: 25px 105.5px 106.4px 33px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
  background-color: #fff;
  position: relative;
  bottom: 987px;
  left: 770px;
}
.ResDetailsDistanceNumImg {
  width: 34.9px;
  height: 18.8px;
  margin: 0 10px 55.7px 0;
  object-fit: contain;
}
.ResDetailsDistanceNumSpan1 {
  width: 172px;
  height: 21px;
  margin: -4px 21px 53.3px 2.4px;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  color: #3a3d3f;
}
.ResDetailsDistanceNumHr {
  width: 295px;
  height: 1px;
  margin: 0 14.5px 0 0px;
  background-color: #d0d0d0;
  position: relative;
  bottom: 40px;
}
.ResDetailsDistanceNumSpanB2 {
  width: 158px;
  height: 69px;
  margin: 0 35px 0 40px;
  text-shadow: 0 0 3px #fff;
  font-family: S-CoreDream-5;
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  color: #8e8e8f;
}
.ResDetailsDistanceNumSpanB3 {
  width: 78px;
  height: 18px;
  margin: 39.3px 0 11.7px 10px;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e8f;
}
.ResDetailsDistanceNumSpanS2 {
  width: 158px;
  height: 69px;
  margin: 0 35px 0 80px;
  text-shadow: 0 0 3px #fff;
  font-family: S-CoreDream-5;
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  color: #8e8e8f;
}
.ResDetailsDistanceNumSpanS3 {
  width: 78px;
  height: 18px;
  margin: 39.3px 0 11.7px 10px;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e8f;
}

.ResDetailsChartBg {
  width: 730px;
  height: 365px;
  margin: 20px 0 0 20px;
  padding: 23.7px 30px 25.7px 33px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
  background-color: #fff;
  position: absolute;
  bottom: 31px;
  right: 25px;
}
.ResDetailsChartImg {
  width: 21.6px;
  height: 19.6px;
  margin: 0 14.4px 44px 0;
  object-fit: contain;
}
.ResDetailsChartSpan {
  width: 172px;
  height: 21px;
  margin: -4px 296px 42.6px 2.4px;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3a3d3f;
}
.ResDetailsChartCarM {
  width: 23.7px;
  height: 8.5px;
  margin: 0 11.1px 8.7px 22.1px;
  border-radius: 6px;
  background-color: #fea031;
}
.ResDetailsChartCarSpanM {
  width: 61px;
  height: 14px;
  margin: 9.8px 55px 39.8px 30px;
  font-family: WelcomeOTFRegular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #8e8e8f;
  position: relative;
  bottom: 10px;
}
.ResDetailsChartCarT {
  width: 23.7px;
  height: 8.5px;
  margin: 0 80.2px 8.7px 82.2px;
  border-radius: 6px;
  background-color: #69a6f7;
}
.ResDetailsChartCarSpanT {
  width: 47px;
  height: 14px;
  margin: 9.8px 0 39.8px 30px;
  font-family: WelcomeOTFRegular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #8e8e8f;
  position: relative;
  bottom: 11px;
}
.ResDetailsChartCarSet {
  position: relative;
  bottom: 45px;
  left: 450px;
}
.ResDetailsChartHr {
  width: 668px;
  height: 1px;
  margin: 0 14.5px 0 0px;
  background-color: #d0d0d0;
  position: relative;
  bottom: 45px;
}
.ResDetailsChartResNum {
  position: relative;
  width: 500px;
  height: 240px;
  right: 10px;
}
.ResDetailsChartContentMSpan1 {
  width: 61px;
  height: 14px;
  margin: 11.4px 122.3px 7px 58.7px;
  font-family: WelcomeOTFRegular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8e8e8f;
}
.ResDetailsChartContentMHr {
  width: 146px;
  height: 1px;
  margin: 0 14.5px 0 58px;
  background-color: #d0d0d0;
  position: relative;
  bottom: -5px;
}
.ResDetailsChartContentMSpan2 {
  width: 64px;
  height: 35px;
  margin: 20px 44.3px 0 58.7px;
  font-family: S-CoreDream-5;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsChartContentMImg {
  width: 37px;
  height: 30.9px;
  margin: 23px 38px 4.1px 0px;
  object-fit: contain;
}
.ResDetailsChartContentTSpan1 {
  width: 61px;
  height: 14px;
  margin: 2.7px 122.3px 7px 58.7px;
  font-family: WelcomeOTFRegular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8e8e8f;
}
.ResDetailsChartContentTHr {
  width: 146px;
  height: 1px;
  margin: 0 14.5px 0 58px;
  background-color: #d0d0d0;
  position: relative;
  bottom: -5px;
}
.ResDetailsChartContentTSpan2 {
  width: 64px;
  height: 35px;
  margin: 20px 41.4px 0 58.7px;
  font-family: S-CoreDream-5;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
}
.ResDetailsChartContentTImg {
  width: 37px;
  height: 32.9px;
  margin: 22px 36.9px 0 5px;
  object-fit: contain;
}
.ResDetailsChartContent {
  position: relative;
  bottom: 24px;
}
.ResDetailsChartContent {
  position: relative;
  left: 450px;
  bottom: 235px;
}