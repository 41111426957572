.resBaseBg {
    background-color: #e8ebef;
}

.resManageBg {
    width: 1497px;
    height: 94.8vh;
    margin: 0 0 0 30px;
    padding: 26px 51px 30px 54px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    right: 20px;
    top: 20px;
}
.resManageBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px -30px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 35px;
    top: 70px;
}
.resManageSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4.5px;
    right: -0.5px;
}
.resManageSpan2 {
    width: 163px;
    height: 29px;
    margin: -1px 77px 41.9px 12px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}
.resManageExcelButton {
    width: 128px;
    height: 36px;
    margin: 25px 13px 0 10px;
    padding: 6.7px 16.5px 6.5px 6.6px;
    border-radius: 5px;
    background-color: #69a6f7;
}
.resManageExcelImage {
    width: 24.8px;
    height: 22.6px;
    margin: 0 8.2px 0.2px 0;
    object-fit: contain;
}
.resManageExcelSpan {
    width: 72px;
    height: 21px;
    margin: 1.8px 0 0 8.2px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    position: relative;
    top: -24px;
    left: 10px;
}
.resManageExcelSet {
    position: absolute;
    bottom: 0px;
    left: 872px;
    z-index: 999;
}

.ResManageTable {
    width: 1402px;
}
.ResManageThead {
    width: 1402px;
    height: 120px;
    margin: -120px 80px 0 0;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    background-color: #dae8fa;
    position: fixed;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.ResManageList {
    overflow-y: auto;
    overflow-x: hidden;
    width: 1402px;
    height: 57vh;
    position: relative;
    top: 24vh;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.ResManageTbody {
    width: 1342px;
    height: 50px;
    margin: 9px 0 8px;
    padding: 12px 112px 11px 38px;
    background-color: #fcfcfc;
    border-bottom: white solid 4px;
    font-family: S-CoreDream-5;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.ResManageTbodyTh {
    width: 145px;
    height: 15px;
    margin: 6px 5px 6px 0;
    font-family: S-CoreDream-5;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}


.downUser {
    font-size: medium;
    font-weight: bold;
    color: #2563eb;
}
.downButton {
    height: 5vh;
    cursor: pointer;
}

@media (min-width: 1240px) {
    .resBase {
        height: 57vh;
        right: 6.7%;
    }
    .resTable {
        margin-top: 3.5%;
    }
    .downUser {
        position: relative;
        top: 5vh;
        right: 35%;
    }
    .downButton {
        position:relative;
        top: 9.5vh;
        right: 75%;
    }
}
@media (min-height: 633px) {
    .resBase {
        height: 58vh;
    }
    .resTable {
        margin-top: 6%;

    }
    .downUser {
        position: relative;
        top: 8vh;
        right: 35%;
    }
    .downButton {
        position:relative;
        top: 12vh;
        right: 85%;
    }
}
@media (min-width: 1880px) {
    .resBase {
        height: 65vh;
    }
    .resTable {
        margin-top: 5%;
        margin-left: 18%;
    }
    .downUser {
        position: relative;
        top: 8vh;
        right: 250%;
    }
    .downButton {
        position:relative;
        top: 11.5vh;
        right: 320%;
    }
}
@media (min-height: 993px) {
    .resBase {
        height: 70vh;
    }
    .resTable {
        margin-top: 6%;
        margin-left: 18%;
    }
    .downUser {
        position: relative;
        top: 8vh;
        right: 260%;
    }
    .downButton {
        position:relative;
        top: 11.5vh;
        right: 330%;
    }
}