.ModifyModalBg {
  width: 550px;
  height: 700px;
  margin: 130px 653px 162px 750px;
  padding: 34px 16.6px 0 38px;
  border-radius: 10px;
  background-color: #fff;
}
.ModifyXButton {
  width: 22px;
  height: 22px;
  margin: -10px 0px 2.8px 58px;
  padding: -12px 5px 8px 4px;
  background-color: #ff6767;
  border-radius: 5px;
}
.ModifyXButtonSpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ModifyModalBB {
  width: 15px;
  height: 15px;
  margin: 3px 8px 4px -30px;
  padding-left: 2.5px;
  border-radius: 2px;
  background-color: #0a6ab3;
  color: #fff;
  font-size: xx-small;
  position: relative;
  left: 30px;
}
.ModifyModalSpan1 {
  color: #fff;
  font-size: small;
  position: relative;
  top: -2.5px;
  right: 0px;
}
.ModifyModalSpan2 {
  width: 170px;
  height: 29px;
  margin: 0px 0px 0px 8px;
  font-family: WelcomeOTFBold;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
  top: -10px;
  left: 20px;
}
.ModifyModalP {
  width: 184px;
  height: 15px;
  margin: 7px 37.4px 51.9px 28px;
  font-family: S-CoreDream-4;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #707070;
  position: relative;
  right: -250px;
}

.ModifyIdImage {
  width: 17.6px;
  height: 19.6px;
  margin: 49.4px 12px 4.9px 20.5px;
  object-fit: contain;
}
.ModifyIdLabel {
  width: 76px;
  height: 18px;
  margin: 48px 16px 42px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyIdH1 {
  width: 76px;
  height: 18px;
  margin: 48px 0.4px 25.8px 10px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.ModifyPWImage {
  width: 17.6px;
  height: 19.6px;
  margin: 10px 12px 4.9px 20.5px;
  object-fit: contain;
}
.ModifyPWLabel {
  width: 76px;
  height: 18px;
  margin: 10px -7px 42px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPWSpan {
  width: 16px;
  height: 18px;
  margin: 12px -5px 0px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPWInput {
  width: 309px;
  height: 35px;
  margin: 5px 37.4px 39.4px 4px;
  padding: 0 0 0 20px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ModifyNameImage {
  width: 17.6px;
  height: 19.6px;
  margin: 0px 12px 4.9px 20.5px;
  object-fit: contain;
}
.ModifyNameLabel {
  width: 76px;
  height: 18px;
  margin: 0px 16px 42px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyNameH1 {
  width: 76px;
  height: 18px;
  margin: 0px 0.4px 25.8px 10px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.ModifyPartImage {
  width: 17.6px;
  height: 19.6px;
  margin: 10px 12px 4.9px 20.5px;
  object-fit: contain;
}
.ModifyPartLabel {
  width: 76px;
  height: 18px;
  margin: 10px 16px 42px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPartH1 {
  width: 150px;
  height: 18px;
  margin: 10px 0.4px 25.8px 10px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPartSpan {
  width: 16px;
  height: 18px;
  margin: 12px -5px 0px -24px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPartInput {
  width: 309px;
  height: 35px;
  margin: 5px 37.4px 39.4px 4px;
  padding: 0 0 0 20px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ModifyPositionImage {
  width: 17.6px;
  height: 19.6px;
  margin: 4.4px 12px 4.9px 20.5px;
  object-fit: contain;
}
.ModifyPositionLabel {
  width: 76px;
  height: 18px;
  margin: 4px 16px 42px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPositionH1 {
  width: 150px;
  height: 18px;
  margin: 5px 0.4px 25.8px 10px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPositionSpan {
  width: 16px;
  height: 18px;
  margin: 12px -5px 0px -24px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPositionInput {
  width: 309px;
  height: 35px;
  margin: 5px 37.4px 39.4px 4px;
  padding: 0 0 0 20px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ModifyPhoneImage {
  width: 17.6px;
  height: 19.6px;
  margin: 10px 12px 4.9px 20.5px;
  object-fit: contain;
}
.ModifyPhoneLabel {
  width: 76px;
  height: 18px;
  margin: 10px -7px 42px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPhoneSpan {
  width: 16px;
  height: 18px;
  margin: 12px -5px 0px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyPhoneInput {
  width: 309px;
  height: 35px;
  margin: 5px 37.4px 39.4px 4px;
  padding: 0 0 0 20px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
}

.ModifyAuthImage {
  width: 17.6px;
  height: 19.6px;
  margin: 0px 12px 4.9px 20.5px;
  object-fit: contain;
}
.ModifyAuthLabel {
  width: 76px;
  height: 18px;
  margin: 0px 16px 42px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ModifyAuthH1 {
  width: 150px;
  height: 18px;
  margin: 0px 0.4px 25.8px 10px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.ModifyCancelButton {
  width: 105px;
  height: 41px;
  margin: 10px 0px 2.8px 58px;
  padding: 8px 5px 8px 4px;
  background-color: #ff6767;
  border-radius: 10px;
}
.ModifyCancelSpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ModifyButton {
  width: 105px;
  height: 41px;
  margin: 10px 32px 2.8px 17px;
  padding: 8px 5px 8px 4px;
  background-color: #69a6f7;
  border-radius: 10px;
}
.ModifySpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.ModifyImage {
  width: 199px;
  height: 126px;
  margin: -80.5px 75px 0 0;
  object-fit: contain;
}

.ModifyUserImage {
  width: 199px;
  height: 146px;
  margin: -75px 75px 0 0;
  object-fit: contain;
}