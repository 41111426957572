@font-face {
    /* 사용할 폰트의 이름 */
    font-family: 'S-CoreDream-5';
    font-display: fallback;

    /* 
        src: 사용할 폰트의 위치 및 형식 
            Safari, Android, iOS => ttf       
            Modern Browsers => woff
            IE6-IE8 => eot
    */

    src: url('./SCDream5.ttf') format('truetype'),
        url('./SCDream5.woff') format('woff'),
        url('./SCDream5.eot?iefix') format('embedded-opentype');

    /* 사용할 폰트의 기본 스타일 */
    font-weight: 300;
    font-style: normal;
}
@font-face {
    /* 사용할 폰트의 이름 */
    font-family: 'S-CoreDream-4';
    font-display: fallback;

    /* 
        src: 사용할 폰트의 위치 및 형식 
            Safari, Android, iOS => ttf       
            Modern Browsers => woff
            IE6-IE8 => eot
    */

    src: url('./SCDream4.ttf') format('truetype'),
        url('./SCDream4.woff') format('woff'),
        url('./SCDream4.eot?iefix') format('embedded-opentype');

    /* 사용할 폰트의 기본 스타일 */
    font-weight: 300;
    font-style: normal;
}