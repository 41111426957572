.ResModalBg {
  width: 550px;
  height: 700px;
  margin: 150px 653px 162px 356.4px;
  padding: 34px 16.6px 0 4.9px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  left: 18%;
  bottom: 2vh;
}
.ResXButton {
  width: 22px;
  height: 22px;
  margin: -10px 0px 2.8px 58px;
  padding: -12px 5px 8px 4px;
  background-color: #ff6767;
  border-radius: 5px;
}
.ResXButtonSpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResModalBB {
  width: 15px;
  height: 15px;
  margin: 3px 8px 4px 0;
  padding-left: 2.5px;
  border-radius: 2px;
  background-color: #0a6ab3;
  color: #fff;
  font-size: xx-small;
  position: relative;
  left: 30px;
}
.ResModalP {
  color: #fff;
  font-size: small;
  position: relative;
  top: -2.5px;
  right: 0px;
}
.ResModalSpan {
  width: 142px;
  height: 29px;
  margin: 0 594px 14px 8px;
  font-family: WelcomeOTFBold;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
  top: -10px;
  left: 20px;
}

.ResNameImage {
  width: 17.6px;
  height: 19.6px;
  margin: 49.4px 15px 4.9px 57.5px;
  object-fit: contain;
}
.ResNameLabel {
  width: 76px;
  height: 18px;
  margin: 49px 16px 4.9px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ResNameInput {
  width: 309px;
  height: 35px;
  margin: 43px 37.4px 33px 0px;
  padding: 10px 14.4px 9px 21px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  outline: none;
}

.ResCarImage {
  width: 23.5px;
  height: 19.7px;
  margin: 11.4px 15px 4.9px 57.5px;
  object-fit: contain;
}
.ResCarLabel {
  width: 56px;
  height: 18px;
  margin: 10px 16px 4.9px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ResCarSelect {
  width: 150px;
  height: 35px;
  margin: 8px 19px 3.9px 16px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  outline: none;
}

.ResStartBB {
  width: 5px;
  height: 5px;
  margin: 26px 0 18.8px 8.1px;
  background-color: #69a6f7;
}
.ResStartLabel {
  position: absolute;
  width: 65px;
  height: 18px;
  margin: -7px 27px 22px 18px;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.StartDatePicker {
  width: 150px;
  height: 35px;
  margin: 13px 19px 13px 101px;
  padding: 9px 10.9px 8px 10px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  outline: none;
}
.StartTimePicker {
  width: 140px;
  height: 35px;
  margin: 12px 0 1.8px;
  padding: 9px 3.5px 8px 0;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  outline: none;
}
.ResStartSet {
  position: relative;
  left: 70px;
}

.ResEndBB {
  width: 5px;
  height: 5px;
  margin: 18px 0 18.8px 8.1px;
  background-color: #69a6f7;
}
.ResEndLabel {
  position: absolute;
  width: 65px;
  height: 18px;
  margin: -7px 27px 22px 18px;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.EndDatePicker {
  width: 150px;
  height: 35px;
  margin: 4px 19px 13px 101px;
  padding: 9px 10.9px 8px 10px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  outline: none;
}
.EndTimePicker {
  width: 140px;
  height: 35px;
  margin: 4px 0 1.8px;
  padding: 9px 3.5px 8px 0;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  outline: none;
}
.ResEndSet {
  position: relative;
  left: 70px;
}

.ResPurposeImage {
  width: 20.2px;
  height: 21.6px;
  margin: 35.4px 10.4px 2.8px 59.5px;
  object-fit: contain;
}
.ResPurposeLabel {
  width: 76px;
  height: 18px;
  margin: 35px 16px 4.9px 0px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ResPurposeSelect {
  width: 150px;
  height: 35px;
  margin: 31px 19px 39.8px 0px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  outline: none;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  outline: none;
}

.ResPDImage {
  width: 17.3px;
  height: 20.8px;
  margin: 5px 15px 4.9px 60px;
  object-fit: contain;
}
.ResPDLabel {
  width: 97px;
  height: 18px;
  margin: 7px 77px 16.2px -4px;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.ResPDTextarea {
  width: 400px;
  height: 100px;
  margin: 0px 37.4px 25px 95px;
  padding: 9px 20px 9px 20px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-5;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
  overflow-y: auto;
  resize: none;
}

.ResModalCancelButton {
  width: 105px;
  height: 41px;
  margin: -10px 0px 2.8px 58px;
  padding: 8px 5px 8px 4px;
  background-color: #ff6767;
  border-radius: 10px;
}
.ResModalCancelSpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}
.ResModalResButton {
  width: 105px;
  height: 41px;
  margin: -10px 32px 2.8px 17px;
  padding: 8px 5px 8px 4px;
  background-color: #69a6f7;
  border-radius: 10px;
}
.ResModalResSpan {
  width: 96px;
  height: 21px;
  font-family: S-CoreDream-5;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.ResModalCarImage {
  width: 96.5px;
  height: 86.2px;
  margin: -32px 26px 0 0;
  object-fit: contain;
  opacity: 0.5;
}