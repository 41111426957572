.scheduleBg {
    width: 81%;
    height: 58vh;
    margin: 25px 0 20px 25px;
    padding: 40.9px 51.6px 27.1px 47px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    right: 25px;
}
.scheduleBB {
    width: 15px;
    height: 15px;
    margin: 0px 0 62px 0px;
    padding: 4.1px;
    border-radius: 2px;
    background-color: #0a6ab3;
    position: absolute;
    top: 32px;
    left: 40px;
}
.scheduleP {
    color: #fff;
    font-size: small;
    position: relative;
    top: -8px;
    right: 1px;
}
.schedulePlan {
    width: 142px;
    height: 29px;
    margin: 0 33.4px 0 16px;
    font-family: WelcomeOTFBold;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    top: -14px;
}
.scheduleRed {
    width: 10px;
    height: 10px;
    margin: 6.5px 8.5px 6.5px 461.9px;
    background-color: #ff6767;
    border-radius: 50%;
    position: relative;
    right: 115px;
}
.scheduleRedSpan {
    width: 28px;
    height: 14px;
    margin: 0 6.3px 2.3px 4.3px;
    font-family: S-CoreDream-4;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    bottom: -4px;
    left: 10px;
}
.scheduleGreen {
    width: 10px;
    height: 10px;
    margin: 6.5px 4.3px 3.3px 6.3px;
    background-color: #26c9a8;
    border-radius: 50%;
    position: relative;
    right: 60px;
}
.scheduleGreenSpan {
    width: 28px;
    height: 14px;
    margin: 2.3px 6.3px 2.3px 4.3px;
    font-family: S-CoreDream-4;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    bottom: -3px;
    left: 10px;
}
.scheduleBalls {
    top: 55px;
    right: 55px;
    position: absolute;
}
.sunday {
    color: #FF0000;
}

.react-calendar.ResCL {
    width: 95%;
    max-width: 100%;
    background-color: #ffffff00;
    font-family: S-CoreDream-4;
    color: #707070;
    position: absolute;
    top: 40px;
}
.react-calendar__navigation {
    display: inline-flex;
    position: absolute;
    left: 40%;
    height: 44px;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}
.react-calendar__navigation button {
    min-width: 30px;
    background: none;
    font-size: x-large;
    position: relative;
    bottom: 10px;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border-radius: 2.5px;
    background-color: #e2eeff5d;
    border: solid 4px #fff;
    position: relative;
    top: 40px;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #bebebe;
}
.react-calendar__century-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__year-view .react-calendar__tile {
    padding: 1em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    height: 7vh;
    padding: 8px 6.6667px;
    background: none;
    text-align: center;
    font-size: medium;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    background-color: #efefef33;
    border-radius: 2.5px;
    border: solid 4px #fff;
    position: relative;
    top: 40px;
}
.react-calendar__tile--now {
    font-weight: bold;
    color: #3f3f46;
    border-radius: 2.5px;
    border: solid 0.5px #badfeb;
}

@media (min-width: 1240px) {
    .listScroll {
        height: 102vh;
        overflow-y: auto;
    }
}

@media (min-height: 633px) {
    .listScroll {
        height: 82vh;
        overflow-y: auto;
    }
}
