.RecentBg {
    width: 15.5%;
    height: 34vh;
    margin: 20px 25px 0 0;
    padding: 31px 27.5px 65.9px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    bottom: 30px;
    left: 25px;
}
.RecentBB {
    width: 15px;
    height: 15px;
    margin: 0px 0 62px 0px;
    padding: 4.1px;
    border-radius: 2px;
    background-color: #0a6ab3;
    position: relative;
    top: 3px;
    left: 5px;
}
.RecentP {
    color: #fff;
    font-size: small;
    position: relative;
    top: -11.5px;
    right: 1px;
}
.RecentSpan {
    width: 202px;
    height: 29px;
    margin: 0 1.5px 54px 16px;
    font-family: WelcomeOTFBold;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 12px;
}
.RecentMorningImage {
    width: 37px;
    height: 30.9px;
    margin: 20px 64.1px 30px 12px;
    object-fit: contain;
}
.RecentMorningImageP {
    width: 61px;
    height: 14px;
    margin: 0px 52.1px 20px 0;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
}
.RecentMorningImageSet{
    position: relative;
    bottom: 15px;
    left: 10px;
}
.RecentMorningH1 {
    width: 64px;
    height: 46px;
    margin: 28.8px 14.8px 4px 5.3px;
    font-family: S-CoreDream-5;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.RecentMorningP {
    width: 90px;
    height: 14px;
    margin: 4px 8.2px 11.7px 24.8px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
}
.RecentMorningWordSet {
    position: relative;
    bottom: 52px;
    right: 15px;
}
.RecentMorningSet {
    position: relative;
    top: -10px;
    left: 10px;
}
.RecentTucsonImage {
    width: 40.6px;
    height: 37.7px;
    margin: 20px 63px 1.5px 9.5px;
    object-fit: contain;
}
.RecentTucsonImageP {
    width: 54px;
    height: 15px;
    margin: 0px 49.6px 20px 9.5px;
    font-family: AppleSDGothicNeo;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
}
.RecentTucsonImageSet {
    position: relative;
    bottom: 15px;
    left: 10px;
}
.RecentTucsonH1 {
    width: 64px;
    height: 46px;
    margin: 18.1px 14.8px 4px 5.3px;
    font-family: S-CoreDream-5;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
}
.RecentTucsonP {
    width: 90px;
    height: 14px;
    margin: 4px 8.2px 0 24.8px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #8e8e8f;
}
.RecentTucsonWordSet {
    position: relative;
    bottom: 38px;
    right: 15px;
}
.RecentTucsonSet {
    position: relative;
    top: -5px;
    left: 10px;
}

.RecentHr {
    width: 245px;
    height: 1px;
    margin: 0px 0 20px;
    color: #d0d0d0;
    border-style: dashed;
    position: relative;
    bottom: 30px;
}