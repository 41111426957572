.CarInspectionModalBg {
  width: 1200px;
  height: 770px;
  margin: 85px 0 126px 332px;
  padding: 25px 35px 29px 30px;
  border-radius: 10px;
  border: solid 1px #707070;
  background-color: #fff;
  position: relative;
}
.CarInspectionXButton {
  width: 22px;
  height: 22px;
  margin: -10px 0px 2.8px 52px;
  padding: -12px 5px 8px 4px;
  position: relative;
  top: -38px;
}
.CarInspectionXButtonSpan {
  width: 35px;
  height: 22px;
  margin: 5px 0 2px;
  font-family: Arial;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #d0d0d0;
}
.CarInspectionBB {
  width: 15px;
  height: 15px;
  margin: -14px 8px 4px -30px;
  padding-left: 2.5px;
  border-radius: 2px;
  background-color: #0a6ab3;
  color: #fff;
  font-size: xx-small;
  position: relative;
  left: 30px;
}
.CarInspectionSpan1 {
  color: #fff;
  font-size: small;
  position: relative;
  top: -4.5px;
  right: 0px;
}
.CarInspectionSpan2 {
  width: 244px;
  height: 29px;
  margin: 0px 0px 0px 8px;
  font-family: S-CoreDream-5;  
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
  top: -10px;
  left: 20px;
}

.CarInspectionCancelButton {
  width: 95px;
  height: 27px;
  margin: 0 0 0 5px;
  padding: 6.9px 0 6.1px 14px;
  border-radius: 5px;
  background-color: #ff6767;
	position: relative;
  left: 280px;
	top: -25px;
}
.CarInspectionCancelSpan {
	width: 52px;
  height: 14px;
  margin: 0 0 0 8px;
  font-family: S-CoreDream-4;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
	position: relative;
	top: -17px;
  color: #fff;
}

.CarInspectionBg {
  width: 385px;
  height: 660px;
  margin: 27px 13px 0 0;
  padding: 42.3px 0 30px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
  background-color: #fff;
}
.CarInspectionCarImg {
  width: 161.7px;
  height: 150.3px;
  margin: 0 115.1px 36.4px 108.1px;
  object-fit: contain;
}
.CarInspectionDistance {
  width: 267px;
  height: 69px;
  margin: 33.1px 77px 84.6px 31px;
  padding: 40.6px 18px 13.4px 24px;
  text-shadow: 0 0 3px #fff;
  font-family: S-CoreDream-5;
  font-size: 60px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  display: inline-block;
  color: #8e8e8f;
  position: relative;
  top: -240px;
}
.CarInspectionKm {
  width: 78px;
  height: 23px;
  margin: 0 152px 2px 25px;
  font-family: S-CoreDream-4;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #8e8e8f;
}
.CarInspectionUserBg1 {
  width: 145px;
  height: 45px;
  padding: 13px 36px 11px 41px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  position: relative;
  bottom: 200px;
}
.CarInspectionUserSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.CarInspectionUserBg2 {
  width: 235px;
  height: 45px;
  margin: 0 0 0 5px;
  padding: 13px 44px 11px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  position: relative;
  bottom: 200px;
}
.CarInspectionUserSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.CarInspectionPartnameBg1 {
  width: 385px;
  height: 45px;
  padding: 14px 36px 10px 41px;
  background-color: #69a6f7;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  position: relative;
  bottom: 200px;
}
.CarInspectionPartnameSpan1 {
  width: 68px;
  height: 21px;
  position: relative;
  bottom: 3px;
}
.CarInspectionPartnameBg2 {
  width: 385px;
  height: 250px;
  margin: 0 0 0 0px;
  padding: 14px 44px 10px 19px;
  background-color: #f8f8f8;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #707070;
  position: relative;
  bottom: 195px;
}
.CarInspectionPartnameSpan2 {
  width: 172px;
  height: 21px;
  position: relative;
  bottom: 3px;
}

.CarInspectionChartBg {
  width: 730px;
  height: 660px;
  margin: 20px 0 0 20px;
  padding: 23.7px 30px 25.7px 33px;
  border-radius: 20px;
  box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
  background-color: #fff;
  position: absolute;
  bottom: 31px;
  right: 25px;
}
.CarInspectionChartImg {
  width: 21.6px;
  height: 19.6px;
  margin: 0 14.4px 44px 0;
  object-fit: contain;
}
.CarInspectionChartSpan {
  width: 172px;
  height: 21px;
  margin: -4px 296px 42.6px 2.4px;
  font-family: S-CoreDream-4;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3a3d3f;
}
.CarInspectionChartCarM {
  width: 12px;
  height: 12px;
  margin: -2px 11.1px 8.7px 140px;
  border-radius: 25px;
  background-color: #26c9a8;
}
.CarInspectionChartCarSpanM {
  width: 61px;
  height: 14px;
  margin: 9.8px 55px 39.8px 20px;
  font-family: WelcomeOTFRegular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #8e8e8f;
  position: relative;
  bottom: 8px;
}
.CarInspectionChartCarT {
  width: 12px;
  height: 12px;
  margin: -2px 80.2px 8.7px 140px;
  border-radius: 25px;
  background-color: #ff6767;
}
.CarInspectionChartCarSpanT {
  width: 47px;
  height: 14px;
  margin: 9.8px 0 39.8px 20px;
  font-family: WelcomeOTFRegular;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #8e8e8f;
  position: relative;
  bottom: 8px;
}
.CarInspectionChartCarSet {
  position: relative;
  bottom: 45px;
  left: 450px;
}
.CarInspectionChartHr {
  width: 668px;
  height: 1px;
  margin: 0 14.5px 0 0px;
  background-color: #d0d0d0;
  position: relative;
  bottom: 45px;
}
.carInspectionCList {
  max-width: 63.2%;
  position: relative;
  bottom: 580px;
  left: 420px;
}

.checkModal {
  position: relative;
  top: 100px;
}
.checkModalBg {
  width: 550px;
  height: 590px;
  margin: 55px 653px 130px 720px;
  padding: 34px 16.6px 0 4.9px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}
.checkXButton {
  width: 22px;
  height: 22px;
  margin: -10px 0px 2.8px 58px;
  padding: -12px 5px 8px 4px;
  background-color: #ff6767;
  border-radius: 5px;
  position: relative;
  left: 650px;
  bottom: 55px;
}
.checkDamageSet {
  position: relative;
  top: 40px;
}
.checkDamageText {
  width: 386px;
  height: 235px;
  margin: -7px 43.4px 15px 89px;
  padding: 9px 14px 8px 14px;
  border: solid 0.5px #d0d0d0;
  background-color: #fff;
  font-family: S-CoreDream-4;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  outline: none;
  resize: none;
}
.checkButtonSet {
  position: relative;
  top: 40px;
}

.emergencyModalBg {
  width: 550px;
  height: 540px;
  margin: 55px 653px 130px 720px;
  padding: 34px 16.6px 0 4.9px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}
.emergencyModalSpan2 {
  width: 234px;
  height: 29px;
  margin: 0px 0px 0px 8px;
  font-family: WelcomeOTFBold;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  position: absolute;
  top: -10px;
  left: 20px;
}