.LoginPageBg {
    background-color: #7bc4ff;;
}
.LoginImage1 {
    position: absolute;
    top: 0px;
}
.LoginImage2 {
    position: absolute;
    top: 0px;
    opacity: 0.6;
}

.LoginFormLogo {
    position: relative;
    width: 254px;
    height: 48.3px;
    top: 55px;
    left: 55px;
}

.LoginFormUsersImage1 {
    width: 104.9px;
    height: 111.7px;
    object-fit: contain;
    position: relative;
    top: 390px;
    left: 118.6px;
}
.LoginFormUsersImage2 {
    width: 72.8px;
    height: 77.6px;
    object-fit: contain;
    position: relative;
    top: 310px;
    left: 49.5px;
}
.LoginFormUsersImage3 {
    width: 72.8px;
    height: 77.6px;
    /* margin: 233px 144.4px 12px 220px; */
    object-fit: contain;
    position: absolute;
    top: 422px;
    left: 220px;
}
.LoginFormUsersP {
    width: 341px;
    height: 38px;
    font-family: S-CoreDream-5;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    position: relative;
    top: 322px;
}
.LoginFormUsersSet {
    position: absolute;
    top: -160px;
    left: 320px;
}

.LoginFormIdImage {
    width: 22px;
    height: 23px;
    margin: 70px 21px 3px 60px;
    object-fit: contain;
    position: absolute;
    z-index: 999;
}
.LoginFormIdInput {
    width: 343px;
    height: 51px;
    margin: 57px 95px 10px 40px;
    padding: 14px 20px 11px 60px;
    border-radius: 5px;
    border: solid 1px #8e8e8f;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    outline: none;
}
.LoginFormIdSet {
    position: absolute;
    left: 280px;
    top: 345px;
}
.LoginFormPwImage {
    width: 22px;
    height: 23px;
    margin: 14px 21px 3px 60px;
    object-fit: contain;
    position: absolute;
    z-index: 999;
}
.LoginFormPwInput {
    width: 343px;
    height: 51px;
    margin: 0px 95px 10px 40px;
    padding: 14px 20px 11px 60px;
    border-radius: 5px;
    border: solid 1px #8e8e8f;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: relative;
    outline: none;
}
.LoginFormPwSet {
    position: absolute;
    left: 280px;
    top: 465px;

}
.LoginFormButton {
    width: 343px;
    height: 51px;
    margin: 20px 95px 374px 40px;
    padding: 10px 126px;
    border-radius: 5px;
    background-color: #004a9b;
    font-family: S-CoreDream-5;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    position: relative;
    top: 470px;
    left: 280px;
}