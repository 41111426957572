.UserAddModalBg {
    width: 550px;
    height: 800px;
    margin: 80px 653px 162px 750px;
    padding: 34px 16.6px 0 38px;
    border-radius: 10px;
    background-color: #fff;
  }
  .UserAddXButton {
    width: 22px;
    height: 22px;
    margin: -10px 0px 2.8px 58px;
    padding: -12px 5px 8px 4px;
    background-color: #ff6767;
    border-radius: 5px;
    position: relative;
    top: -50px;
  }
  .UserAddXButtonSpan {
    width: 96px;
    height: 21px;
    font-family: S-CoreDream-5;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .UserAddModalBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px -30px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 30px;
  }
  .UserAddModalSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -2.5px;
    right: 0px;
  }
  .UserAddModalSpan2 {
    width: 170px;
    height: 29px;
    margin: 0px 0px 0px 8px;
    font-family: WelcomeOTFBold;
    font-size: 25px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
  }
  .UserAddModalP {
    width: 184px;
    height: 15px;
    margin: 7px 37.4px 51.9px 28px;
    font-family: S-CoreDream-4;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #707070;
    position: relative;
    right: -250px;
  }
  
  .UserAddIdImage {
    width: 17.6px;
    height: 19.6px;
    margin: 49.4px 12px 4.9px 20.5px;
    object-fit: contain;
  }
  .UserAddIdLabel {
    width: 76px;
    height: 18px;
    margin: 48px 16px 42px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddIdInput {
    width: 309px;
    height: 35px;
    margin: 45px 37.4px 39.4px -18px;
    padding: 0 0 0 20px;
    border: solid 0.5px #d0d0d0;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    outline: none;
  }
  
  .UserAddPWImage {
    width: 17.6px;
    height: 19.6px;
    margin: 10px 12px 4.9px 20.5px;
    object-fit: contain;
  }
  .UserAddPWLabel {
    width: 76px;
    height: 18px;
    margin: 10px -7px 42px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddPWSpan {
    width: 16px;
    height: 18px;
    margin: 12px -5px 0px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddPWInput {
    width: 309px;
    height: 35px;
    margin: 5px 37.4px 39.4px 4px;
    padding: 0 0 0 20px;
    border: solid 0.5px #d0d0d0;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    outline: none;
  }
  
  .UserAddNameImage {
    width: 17.6px;
    height: 19.6px;
    margin: 10px 12px 4.9px 20.5px;
    object-fit: contain;
  }
  .UserAddNameLabel {
    width: 76px;
    height: 18px;
    margin: 10px 16px 42px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddNameInput {
    width: 309px;
    height: 35px;
    margin: 5px 37.4px 39.4px -18px;
    padding: 0 0 0 20px;
    border: solid 0.5px #d0d0d0;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    outline: none;
  }
  
  .UserAddPartImage {
    width: 17.6px;
    height: 19.6px;
    margin: 10px 12px 4.9px 20.5px;
    object-fit: contain;
  }
  .UserAddPartLabel {
    width: 76px;
    height: 18px;
    margin: 10px 16px 42px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddPartInput {
    width: 309px;
    height: 35px;
    margin: 5px 37.4px 39.4px -18px;
    padding: 0 0 0 20px;
    border: solid 0.5px #d0d0d0;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    outline: none;
  }

  .UserAddPositionImage {
    width: 17.6px;
    height: 19.6px;
    margin: 10px 12px 4.9px 20.5px;
    object-fit: contain;
  }
  .UserAddPositionLabel {
    width: 76px;
    height: 18px;
    margin: 10px 16px 42px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddPositionInput {
    width: 309px;
    height: 35px;
    margin: 5px 37.4px 39.4px -18px;
    padding: 0 0 0 20px;
    border: solid 0.5px #d0d0d0;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    outline: none;
  }
  
  .UserAddPhoneImage {
    width: 17.6px;
    height: 19.6px;
    margin: 10px 12px 4.9px 20.5px;
    object-fit: contain;
  }
  .UserAddPhoneLabel {
    width: 76px;
    height: 18px;
    margin: 10px -7px 42px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddPhoneSpan {
    width: 16px;
    height: 18px;
    margin: 12px -5px 0px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddPhoneInput {
    width: 309px;
    height: 35px;
    margin: 5px 37.4px 39.4px 4px;
    padding: 0 0 0 20px;
    border: solid 0.5px #d0d0d0;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    outline: none;
  }
  
  .UserAddAuthImage {
    width: 17.6px;
    height: 19.6px;
    margin: 10px 12px 4.9px 20.5px;
    object-fit: contain;
  }
  .UserAddAuthLabel {
    width: 76px;
    height: 18px;
    margin: 10px 16px 42px 0px;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
  }
  .UserAddAuthSelect {
    width: 309px;
    height: 35px;
    margin: 5px 37.4px 39.4px -18px;
    padding: 0 0 0 20px;
    border: solid 0.5px #d0d0d0;
    background-color: #fff;
    font-family: S-CoreDream-5;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    outline: none;
  }
  
  .UserAddCancelButton {
    width: 105px;
    height: 41px;
    margin: 10px 0px 2.8px 58px;
    padding: 8px 5px 8px 4px;
    background-color: #ff6767;
    border-radius: 10px;
  }
  .UserAddCancelSpan {
    width: 96px;
    height: 21px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .UserAddButton {
    width: 105px;
    height: 41px;
    margin: 10px 32px 2.8px 17px;
    padding: 8px 5px 8px 4px;
    background-color: #69a6f7;
    border-radius: 10px;
  }
  .UserAddSpan {
    width: 96px;
    height: 21px;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  
  .UserAddImage {
    width: 199px;
    height: 166px;
    margin: -70px 75px 0 0;
    object-fit: contain;
  }