.carListBg {
    background-color: #e8ebef;
}

.carBaseBg {
    width: 1497px;
    height: 94.8vh;
    margin: 0 0 0 30px;
    padding: 26px 51px 30px 54px;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(105, 166, 247, 0.5);
    background-color: #fff;
    position: absolute;
    right: 20px;
    top: 20px;
}
.carBaseBB {
    width: 15px;
    height: 15px;
    margin: 3px 8px 4px -30px;
    padding-left: 2.5px;
    border-radius: 2px;
    background-color: #0a6ab3;
    color: #fff;
    font-size: xx-small;
    position: relative;
    left: 35px;
    top: 70px;
}
.carBaseSpan1 {
    color: #fff;
    font-size: small;
    position: relative;
    top: -4.5px;
    right: -0.5px;
}
.carBaseSpan2 {
    width: 163px;
    height: 29px;
    margin: -1px 77px 41.9px 12px;
    font-family: S-CoreDream-5;
    font-size: 25px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: absolute;
    top: -10px;
    left: 20px;
}

.carManageAddButton {
    position: absolute;
    right: 55px;
    top: 380px;
    z-index: 999;
}
.carManageAddImage {
    width: 27px;
    height: 27px;
    object-fit: contain;
}

.carBaseSet {
    position: relative;
    top: -130px;
}

.carTBaseTable {
    width: 680px;
    position: relative;
}
.carTBaseThead {
    width: 680px;
    height: 65px;
    margin: 0px 42px 11px 0;
    padding: 24px 0 9px 28px;
    background-color: #dae8fa;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: fixed;
    z-index: 999;
}
.carBaseSpan {
    width: 300px;
    height: 14px;
    margin: 18px 0 0 327px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fdfdfd;
    position: relative;
    top: 20px;
}
.carTBaseList {
    overflow-y: auto;
    overflow-x: hidden;
    height: 68vh;
    position: relative;
    top: 24vh;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.carTBaseTbody {
    width: 1342px;
    height: 50px;
    margin: 9px 0 8px;
    padding: 12px 112px 11px 38px;
    background-color: #fcfcfc;
    font-family: S-CoreDream-5;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    position: relative;
    top: 65px;
}
.carTBaseTbodyTh {
    width: 145px;
    height: 15px;
    margin: 6px 5px 6px 0;
    font-family: S-CoreDream-5;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    position: relative;
    left: 60px;
}
.carTBaseTbodyTd {
    width: 9px;
    height: 14px;
    color: #00309b;
    position: relative;
}
.carTBaseTbodyTdButton {
    position: absolute;
    left: 635px;
}
.carTLightImg {
    width: 26.6px;
    height: 24.8px;
    margin: 0 31.7px 0 -20px;
    object-fit: contain;
    position: absolute;
    top: 14px;
}
.carTDarkImg {
    width: 26.6px;
    height: 24.8px;
    margin: 0 31.7px 0 -20px;
    object-fit: contain;
    position: absolute;
    top: 14px;
}

.carMBaseTable {
    width: 680px;
    position: relative;
}
.carMBaseThead {
    width: 680px;
    height: 65px;
    margin: 0px 42px 11px 0;
    padding: 24px 0 9px 28px;
    background-color: #dae8fa;
    font-family: S-CoreDream-5;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #707070;
    position: fixed;
    z-index: 999;
}
.carMBaseSpan {
    width: 236px;
    height: 14px;
    margin: 18px 0 0 315px;
    font-family: WelcomeOTFRegular;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fdfdfd;
}
.carMBaseList {
    overflow-y: auto;
    overflow-x: hidden;
    height: 68vh;
    position: relative;
    top: 24vh;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.carMBaseTbody {
    width: 1342px;
    height: 50px;
    margin: 9px 0 8px;
    padding: 12px 112px 11px 38px;
    background-color: #fcfcfc;
    font-family: S-CoreDream-5;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    position: relative;
    top: 65px;
}
.carMBaseTbodyTh {
    width: 145px;
    height: 15px;
    margin: 6px 5px 6px 0;
    font-family: S-CoreDream-5;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #707070;
    position: relative;
    left: 60px;
}
.carMLightImg {
    width: 26.6px;
    height: 24.8px;
    margin: 0 31.7px 0 -20px;
    object-fit: contain;
    position: absolute;
    top: 14px;
}
.carMDarkImg {
    width: 26.6px;
    height: 24.8px;
    margin: 0 31.7px 0 -20px;
    object-fit: contain;
    position: absolute;
    top: 14px;
}

.downUser {
    font-size: medium;
    font-weight: bold;
    color: #2563eb;
}
.downButton {
    height: 5vh;
    cursor: pointer;
}

.carMBaseBorder {
    width: 526.3px;
    height: 0.5px;
    margin: 15.3px 14.2px 12.3px 65.8px;
    background-color: #7070709f;
}
.carMBaseBorderTr {
    width: 526.3px;
    height: 0.5px;
    margin: 0px 14.2px 12.3px 65.8px;
    background-color: #d0d0d09f;
    position: absolute;
}
.carMItemBg {
    background-color: #fcfcfc;
}
.carMItemButton {
    width: 780px;
}
.carMItemDownButtonSpan {
    width: 9px;
    height: 14px;
    color: #00309b;
    position: absolute;
}
.carMItemDownButton {
    position: relative;
    left: 352px;
    bottom: 10px;
}
.carManageAddButton {
    position: relative;
    left: 170px;
    top: 10px;
    z-index: 999;
}
.carManageAddImage {
    width: 27px;
    height: 27px;
    object-fit: contain;
}
.carManageAddImage:hover {
    opacity: 0.9;
}
.carManageDetailsButton {
    width: 27px;
    height: 27px;
    position: relative;
    left: 170px;
    top: 6px;
    z-index: 999;
    background-color: #00309b;
    border-radius: 5px;
}
.carManageDetailsImage {
    width: 18px;
    height: 18px;
    object-fit: contain;
    position: relative;
    left: 5px;
}
.carManageDetailsButton:hover {
    opacity: 0.9;
}
.carManageAddButton2 {
    position: relative;
    left: 188px;
    top: 10px;
    z-index: 999;
}
.carManageAddImage2 {
    width: 27px;
    height: 27px;
    object-fit: contain;
}
.carManageAddImage2:hover {
    opacity: 0.9;
}

@media (min-width: 1240px) {
    .resBase {
        height: 57vh;
        right: 6.7%;
    }
    .resTable {
        margin-top: 3.5%;
    }
    .downUser {
        position: relative;
        top: 5vh;
        right: 35%;
    }
    .downButton {
        position:relative;
        top: 9.5vh;
        right: 75%;
    }
}
@media (min-height: 633px) {
    .resBase {
        height: 58vh;
    }
    .resTable {
        margin-top: 6%;

    }
    .downUser {
        position: relative;
        top: 8vh;
        right: 35%;
    }
    .downButton {
        position:relative;
        top: 12vh;
        right: 85%;
    }
}
@media (min-width: 1880px) {
    .resBase {
        height: 65vh;
    }
    .resTable {
        margin-top: 5%;
        margin-left: 18%;
    }
    .downUser {
        position: relative;
        top: 8vh;
        right: 250%;
    }
    .downButton {
        position:relative;
        top: 11.5vh;
        right: 320%;
    }
}
@media (min-height: 993px) {
    .resBase {
        height: 70vh;
    }
    .resTable {
        margin-top: 6%;
        margin-left: 18%;
    }
    .downUser {
        position: relative;
        top: 8vh;
        right: 260%;
    }
    .downButton {
        position:relative;
        top: 11.5vh;
        right: 330%;
    }
}